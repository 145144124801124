import Vue from "vue";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
// @ts-ignore
import OverView from "@/views/Admin/ReportsOnDemand/Create/Overview.vue";

export default Vue.extend({
name: "CreateReportOnDemand",
props: {},
components: {
    Alertize,
    OverView
},
data: () => ({}),
created(){},
mounted(){},
computed:{},
methods:{},
    
});