// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import { Paginated, SortingOption } from "@/interfaces/paginated";
// @ts-ignore
import i18n from "@/plugins/i18n";

export function preparedColumns(){
    return [
        {
            text: i18n.t('reportOnDemand.table.id'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "id",
        },
        {
            text: i18n.t('reportOnDemand.table.report_type'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "report_type",
        },
        {
            text: i18n.t('reportOnDemand.table.parameters'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "parameters",
        },
        {
            text: i18n.t('reportOnDemand.table.status'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "status",
        },
        {
            text: i18n.t('reportOnDemand.table.user'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "email",
        },
        {
            text: i18n.t('reportOnDemand.table.created'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "start_date",
        },
        {
            text: i18n.t('reportOnDemand.table.complete'),
            align: "center",
            sortable: false,
            filterable: false,
            value: "finish_date",
        },
    ];
};

export function preparedData(){
    return {
        paginated: { page: 1, limit: 25 } as Paginated,
        filters: {},
        options: getDefaultSortingOption("id"),
        table: {
            headers: [],
            items: [],
        }
    }
}

export function itemsReportType(){
    return {
        store_attribution_report: {
            values: ["country", "campaign", "listPois"],
            multiselect: {
                country: false,
                campaign: false,
                listPois: true
            },
        },
        geo_report: {
            values: ["campaign", "start_date", "end_date"],
            multiselect: {
                campaign: false,
            },
        },
        campaign_insight: {
            values: ["country", "campaign"],
            multiselect: {
                country: true,
                campaign: true,
            },
        },
        unique_devices_report: {
            values: ["campaign", "start_date", "end_date"],
            multiselect: {
                campaign: false,
            },
        }
    }
}

export function reportsTypes(){
    return [
        {
            title: i18n.t('reportOnDemand.separators.store_attribution'),
            type: "store_attribution_report",
            keyRoute:"report_demand_store_attribution",
            searchDates: false,
        },
        {
            title: i18n.t('reportOnDemand.separators.gep_report'),
            type: "geo_report",
            keyRoute:"report_demand_geo",
            searchDates: true,
        },
        {
            title: i18n.t('reportOnDemand.separators.campaign'),
            type: "campaign_insight",
            keyRoute:"report_demand_campaign_insight",
            searchDates: false,
        },
        {
            title: i18n.t('reportOnDemand.separators.unique_report'),
            type: "unique_devices_report",
            keyRoute:"report_demand_unique_devices",
            searchDates: true
        }
    ]
}

export function dataReports(){
    return {
        store_attribution_report: {
            model: {
                campaign_id: null,
                country_id: null, 
                list_id: []
            },
            resources:{
                campaign: [],
                country: [], 
                list_pois: []
            },
            loading:{
                campaign: false,
                country: false, 
                list_pois: false
            }
        },
        geo_report: {
            model: {
                campaign_id: null,
                start_date: "",
                end_date: ""
            },
            resources:{
                campaign: [],
            },
            loading:{
                campaign: false,
            }
        },
        campaign_insight: {
            model: {
                campaign_id: [],
                country_id: [], 
            },
            resources:{
                campaign: [],
                country:[]
            },
            loading:{
                campaign: false,
                country: false, 
            }
        },
        unique_devices_report: {
            model: {
                campaign_id: null,
                start_date: "",
                end_date: "" 
            },
            resources:{
                campaign: [],
            },
            loading:{
                campaign: false,
            }
        }
    }
}