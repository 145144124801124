import Vue from "vue";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
// @ts-ignore
import Report from "@/views/Admin/ReportsOnDemand/Dependencies/Report.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { reportsTypes } from "@/views/Admin/ReportsOnDemand/Logic/Utils.ts";
// @ts-ignore
import Separator from "@/components/Content/Separator.vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
name: "OverViewReportOnDemand",
props: {},
components: {
    Alertize,
    Report,
    Separator
},
data: () => ({
    valid: false,
    reportType: reportsTypes(),
}),
created(){},
mounted(){
    this.getAccount();
},
computed:{
    ...mapGetters("account", ["account"]),
	...mapGetters("internationalization", ["getLanguage"]),
    getReportTypes() {
        return this.reportType.filter(item => {
            // If is Rappy Amplify excludes unique device report
            if(this.account && this.account.account_type === "rappi_amplify") {
                return !["unique_devices_report", "store_attribution_report"].includes(item.type);
            }
            if(this.account && this.account.account_type === "self_service") {
                return !["store_attribution_report"].includes(item.type);
            }
            return true;
        });
    }
},
methods:{
    ...mapActions("account", ["getAccount"]),
	async updateReportType(){
        this.reportType = reportsTypes();
    }
},
watch: {
    async getLanguage(lang) {
        await this.updateReportType();
    }
},
});
